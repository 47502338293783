/* .sub-header-class {
    margin-left: 40px;
} */

.white-bg {
    background-color: #ffffff;
}



.breadcrumb {
    padding: 8px 15px;
    
    list-style: none;
    
    border-radius: 4px;
}

.breadcrumb>li {
    display: inline-block;

}

.breadcrumb>li+li:before {
    padding: 0 5px;
    color: #ccc;
    content: "/\00a0";
}

.breadcrumb>.active {
    color: #777;
}

.session-title {
    padding-top: 56px;
}

.title-action {
    text-align: right;
    padding-top: 30px;
}