* {
  font-family: "Montserrat";
  font-size: 12px;
}

body {
  background: #f4f7fa !important;

}

.maplabel {
  font-weight: bold;
  font-size: .75rem !important;
}

.fa-file-pdf.file-thumbnail {
  color: tomato;
}

.fa-file-word.file-thumbnail {
  color: #0d6efd;
}

.fa-file-excel.file-thumbnail {
  color: #198754;
}


footer {
  background-color: #647393;
  position: absolute;
  bottom: 0;
  width: 100%;
  
  height: 60px;
}

footer a {
  text-decoration: none;
}

.header {
  background: none;
}

.header,
.header a {
  color: #1a293b
}

.sidebar-header {
  color: #fff;
}

.btn-floating {
  border-radius: 50% !important;
}

.field-label {
  font-size: smaller;
}

.field-data {
  border-bottom: 1px solid #ccc;

}

.related-list-table a {
  text-decoration: none;
}


.data-table a {
  text-decoration: none;
}

.data-table th {
  background-color: #FFFFFF !important;
  color: #0f0f0f;
  font-weight: 400;
  text-transform: uppercase;
  white-space: nowrap;
}

.data-table {
  border: 1px solid #1a293b;
  border-radius: 5px;
}

.view-form label {
  font-size: smaller;
  display: block;
  margin-top: 1rem;
  text-transform: uppercase;
}

.view-form-header {
  background-color: #1a293b;
  color: #fff;
  padding: .5rem;
  border-radius: 5px 5px 0 0;
}

.form-detail,
.form-edit {
  border: 1px solid #ccc;
  padding-bottom: 1rem;
  background-color: rgba(255, 255, 255, .75);
}

.view-form span {
  display: block;
  border-bottom: 1px solid #ccc;
}

.fl {
  padding-left: 0px !important;
}

.fr {
  padding-right: 0px !important;
}

.data-table {
  font-size: smaller;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }


}

input[type=number].no-spinner::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.react-pdf__Page {
  margin-top: 10px;
}

.react-pdf__Page__textContent {
  border: 1px solid darkgrey;
  box-shadow: 5px 5px 5px 1px #ccc;
  border-radius: 5px;
}

.react-pdf__Page__annotations.annotationLayer {
  padding: 20px;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
}


.all-page-container {
  height: 100%;
  max-height: 500px;
  overflow: auto;
}

.myTable {
  width: 800px;
}

.fc-event {
  cursor: pointer;
}

table.fc-col-header {
  background-color: #1a293b;
}

table.fc-col-header a {
  color: #fff !important;
}

th.fc-col-header-cell {
  padding: .5rem 0 .5rem 0 !important;
  
}

.circle-icon {
  background: #ffc0c0;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  text-align: center;
  line-height: 100px;
  vertical-align: middle;
  padding: 30px;
}

.fa-stack.small {
  font-size: 2em;
}

i {
  vertical-align: middle;
}

input.form-control[required] {
  border-left: 4px solid red;
}

input.form-control[required] {
  border-left: 4px solid red;
}

textarea.form-control[required] {
  border-left: 4px solid red;
}

.form-select[required] {
  border-left: 4px solid red;
}

.select-staff span {
  border: none !important;
}

.select-staff .Select-heading {
  height: 30px !important;
  border-left: 4px solid #dc3545;
}

.select-staff .Select-item {
  margin: 0 !important;
  padding: .5rem !important;
}

.section-header {
  background-color: #ccc;
  padding-top: 15px;
  padding-left: 15px;
  color: #000000 !important;
  text-transform: uppercase;
  padding-bottom: 10px;
}

.color-text {
  color: #000000 !important;
}

.custom-select .css-1xc3v61-indicatorContainer,
.custom-select .css-15lsz6c-indicatorContainer {
  padding: 0;

}

.custom-select input {
  height: 1rem !important;
}

.custom-select .css-13cymwt-control {
  min-height: auto;
}

.custom-select .css-t3ipsp-control {
  min-height: auto;
}

.react-wizard-addon .wizard-container .card {
  min-height: 620px;
}

.react-wizard-addon .wizard-container .card .card-header {
  background-color: #1a293b;
}

.react-wizard-addon .wizard-container .card .card-header .card-title {
  color: white;
}

.react-wizard-addon .wizard-container .card .card-header .wizard-navigation ul li a {
  color: white;
}

.react-wizard-addon .wizard-container .card .card-header .wizard-navigation .moving-tab {
  color: #1a293b;
}

.react-wizard-addon .wizard-container .card .card-footer div .btn-next {
  background-color: #1a293b;
}

.react-wizard-addon .wizard-container .card .card-footer div .btn-previous {
  background-color: #1a293b;
}

.react-wizard-addon .wizard-container .card .card-footer div .btn-finish {
  background-color: #1a293b;
}

.sibling-checkbox-label-size .form-check-input {
  margin-top: 4px;
}

.error-message {
  color: red;
}

.custom-tab {
  color: rgb(19, 19, 19);
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: "12px";
}

.custom-tab:hover {
  /* background-color: blue;
      color: white; 
      cursor: pointer; */
}

.headingTimeTable {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.printTimeTable {
  text-align: center;
}

.printTimeTable div {
  padding: 7px;
}

.timeTableEditRow {
  background-color: #1a293b;
  color: white;
}

.validationRow {
  border-color: red;
}

#teacher1 {
  border: 2px;
  color: red;
}

.timetablehome {
  background-color: grey;
  color: black;
  margin-left: 6px;
}

.style {
  border: 2px solid red;
}

.error-timetable {
  color: red;
  font-weight: bold;
  padding: 4px;
  margin-bottom: 0px;
}




.dropdown-menu {
  --bs-dropdown-min-width: 80px !important;
}

.book_and_isuue_input {
  height: 3.1rem;
}


.select-dropdown-btn .dropdown-toggle {
  width: 80px;
}




.categoryList>tbody>tr>.tbody-td:nth-child(1) {
  width: 10%;
}

.categoryList>tbody>tr>.tbody-td:nth-child(2) {
  width: 74%;
}

.categoryList>tbody>tr>.tbody-td:nth-child(3) {
  width: 16%;
}

.categoryList>tbody>tr>.tbody-td>span {
  color: #0d6efd;
  cursor: pointer;
}

.btnHover {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.btnHover .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #18a689 !important;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  right: -2rem;
}

.btnHover .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.btnHover:hover .tooltiptext {
  visibility: visible;
}

.btnHoverAtt {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.btnHoverAtt .tooltiptextAtt {
  visibility: hidden;
  width: 120px;
  background-color: #18a689 !important;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -60px;
}

.btnHoverAtt .tooltiptextAtt::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.btnHoverAtt:hover .tooltiptextAtt {
  visibility: visible;
}

.modalstyle {
  background-color: #1a293b !important;
  color: white;
}

.highlight-before {
  background-color: green;
  color: white;
  
}

.App {
  text-align: center;
  padding: 20px;
}

.selected-date {
  margin-top: 20px;
  font-size: 1.2em;
}

.react-calendar {
  margin: 0 auto;
}

.holiday-highlight {
  background-color: #ffcccc !important;
  
  color: #ff0000 !important;
  
}

.modal-body {
  max-height: 70vh;
  
}

.table {
  width: 100%;
  table-layout: fixed;
}

.table th,
.table td {
  word-wrap: break-word;
}

.reportTable {
  border: 1px dashed black;
  width: 100%;
  text-align: center;
}


.reportTable th {
  border: 1px dashed black;
  text-align: center;
  background-color: #293846;
  color: white;
}

.reportTable td {
  border: 1px dashed black;
  text-align: center;
  font-weight: 600;
  padding: 5px;
  background-color: white;
}

.reportTable tr:nth-child(even) {
  background-color: #bbbbbb;
}



.btn-primary {
  background-color: #18a689 !important;
  color: black;
  border: none !important;
}


.bg-secondary {
  background-color: rgb(108 117 125) !important;
}


.thead-tr th {
  background-color: #293846 !important;
  color: white !important;
  font-weight: bold;
  font-size: 12px;
  padding: 5px 5px;
}

.edit-button {
  border: 1px solid #0000005e !important;
  background-color: #ffffff !important;
  color: #293846 !important;
}

.add-new-button:hover {
  background: #18a689 !important;
  border: 1px solid #18a689 !important;
  color: #ffffff !important;
  font-weight: 600;
}

.add-new-button {
  border-color: #18a689 !important;
}

.modal-header {
  background-color: #293846;
  color: white;
  font-weight: 500;
}

.btn-close{
  background-color: white !important;
  opacity: 1 !important;
}


.btn-check:focus+.btn-primary, .btn-primary:focus{box-shadow: none !important;}

.quick-launcher-model{margin-left: 10rem ;}

.tooltipreceipt{display: none;}
.btn-receipt:hover.tooltipreceipt{display: block;}

.login-form-content {
  width: 80%;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  overflow: hidden;
  border-radius: 20px;

}
.login-form-section {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 4rem;

}

.login-img {
  width: 50%;
  border-right: 1px solid #18a689 !important;
  height: 84vh;
}

.login-img img {
  width: 100%;
  margin-top: -2px;
}

.login-content {
  display: flex;
  flex-direction: row;
}

.login-form{width: 50%;}

.box1{
  background-color: #18a689ad !important;
    padding: 100px;
    position: absolute;
    top: 0rem;
    right: 0;
    border-radius: 0px 0px 1px 190px;
}

.box2{
  padding: 85px;
    background-color: #18a689 !important;
    position: absolute;
    right: 0px;
    border-radius: 0px 0px 0px 176px;
}

.indicrm{
  width: 203px;
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
}

.studentProfile{
  display: flex;
  text-transform: uppercase;
}

.studentProfile .row{
  padding: 5px;
}

.studentProfile .row>div:nth-child(2){
  width: 34.333333%;
  font-weight: 900;
}

.student-image{
  display: flex;
  justify-content: center;
  align-items: center;
}

.student-image > img{
  width: 150px;
}

.resultTable{
  width: 100%;
  text-align: center
}

.resultTable th{
  border: 1px solid black;
  text-align: center;
  font-weight: 900;
  padding: 5px;
}

.resultTable td{
  border: 1px solid black;
  text-align: center;
  font-weight: 900;
  padding: 5px;
}


.atten_header{
  font-size: medium;
}
.hrstyle {
  margin-top: 12px;
}
.atten_prop {
  margin-top: 20px;
}
.savebutton{
  text-align: right;
}
.headingstyle{
  text-align: center;
  font-weight: bold;
}


.add-issue-book .css-13cymwt-control {
  border-left: 4px solid red !important;
}

.react-datepicker-wrapper{
  width : 100%;
}
.react-datepicker__input-container{
  height: 35px;
}

.spinner{
  display: block;
  margin: 0 auto;
  border-Color: red;
}

.student-result-switch .form-check-input:checked {
  background-color: #159279b9; 
  border-color: #159279b9; 
}


.select-name-btn>button {
  background-color: white !important;
  border: 2px solid #0c0c0c !important;
  color: #0c0c0c !important;
  font-weight: bolder;
}

.all_Quick_launcher{
  margin-bottom: 100%;
}


.fee_master-custom-header th:nth-child(10) {
  width: 150px;
  text-align: center;
}


.switch-true .form-check-input:checked {
  background-color: #18a689 !important;
  border-color: #18a689 !important;
}


.switch-false .form-check-input {
  background-color: #dc3545 !important;
  border-color: #dc3545 !important;
}


.late-joinee .form-check-input:checked {
  background-color:#f4a46c;
 
  
}


#DatePickerpadding{
  padding: 7px;
}

.modal-dialog {
  max-width: 80%; 
  margin: auto;
}

.modal-body {
  max-height: 70vh; 
} 

.toast-error {
  color: rgb(218, 3, 3) !important;
}

.react-datepicker__day--highlighted {
  background-color: #228564fd !important;
  color: white !important;              
  border-radius: 50% !important; 
  font-size: 15px !important; 
  font-weight: bold !important;
}

.forget-form-content {
  width: 32%;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.forget-form-content p{
  text-align: center;
  margin: 10px 0px;
  
}

.forget-form-content input{
  border-radius: 20px;
}

.submit{
  border-radius: 20px !important;
}




.conversation {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  width: 100%;
}


.message-container {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  word-wrap: break-word;
}

.message-container.sent {
  align-items: flex-end;
}

.message-container.received {
  align-items: flex-start;
}


.message {
  background-color: #dcf8c6;
  
  color: #000;
  border-radius: 10px;
  padding: 10px 15px;
  margin: 5px 0;
  font-size: 14px;
  word-wrap: break-word;
  max-width: 80%;
  position: relative;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
}

.message.sent {
  background-color: #dcf8c6;
  align-self: flex-end;
}

.message.received {
  background-color: #ffffff;
  align-self: flex-start;
  border: 1px solid #e5e5e5;
}


.metadata {
  display: flex;
  justify-content: flex-end;
  font-size: 12px;
  color: #555;
  margin-top: 5px;
}

.metadata .time {
  color: #999;
}

.metadata.received {
  justify-content: flex-start;
}

@media (max-width: 768px) {
  .message {
    font-size: 12px;
    max-width: 90%;
  }
}
.student-profile-picture {
  height: 25vh;
  border-radius : 100% !important; 
  aspect-ratio : 10/10; 
  border : 3px solid black ;
}

#emp_dob[readonly]{
  background-color: #FFFFFF !important;
}
#student_dob[readonly] {
  background-color: #FFFFFF !important;
}

textarea {
  width: 100%;
  height: 120px;
  font-size: 14px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: none;
}

.toolbar {
  display: flex;
  gap: 8px;
  margin-top: 5px;
}

.toolbar button {
  padding: 5px 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 3px;
  background-color: #f9f9f9;
  cursor: pointer;
  transition: background-color 0.2s;
}

.toolbar button:hover {
  background-color: #e0e0e0;
}

.emoji-picker {
  position: absolute;
  z-index: 1000;
  margin-top: 10px;
}

.char-count {
  font-size: 12px;
  color: #555;
  text-align: right;
}

.template-preview-container {
  font-family: 'Segoe UI Emoji', 'Apple Color Emoji', 'Noto Color Emoji', sans-serif;
  max-width: 400px;
  margin: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  overflow: hidden;
  background-image: url("https://i.pinimg.com/736x/8c/98/99/8c98994518b575bfd8c949e91d20548b.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #f8f9fa;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.template-title {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  border-bottom: 1px solid #ddd;
  background: #f5f5f5;
}

.template-preview {
  position: relative;
  padding: 15px;
  margin: 20px;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
}
.template-header {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  margin-bottom: 8px;
}

.template-body {
  font-size: 20px;
  line-height: 1.5;
  color:rgba(0, 0, 0);
  margin-bottom: 15px;
}

.footer-preview {
  font-size: 14px;
  color: #555;
  margin-top: 15px;
}

@media (max-width: 768px) {
  .template-preview-container {
    max-width: 100%;
  }

  .footer-preview {
    font-size: 12px;
  }
}

.button-preview {
  margin-bottom: 15px; 
  display: flex;
  flex-direction: column;
  align-items: center; 
}

.button-preview .btn i {
  margin-right: 10px; 
}
.button-preview .btn  {
  pointer-events: none;
}
.button-preview a {
  text-decoration: none; 
  width: 100%; 
}
.custom-dropdown-toggle::after{
  content: none !important;
}
.textarea-style {
  height: 0px;
}




.promotionTable thead tr th:nth-child(6) .promote-header{
  display: flex;
  
  gap: 5px;
}
.homeAnouncement {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Show only two lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  
}
/* Ensures the modal body is scrollable */
/* .modal-body {
  max-height: 70vh;
  overflow-y: auto;
} */

/* Ensures the editor container fits within the modal */
.resizable-editor {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
}

/* Styling for the text editor */
.Editor {
  width: 100%;
  max-width: 100%;
  min-height: 200px;
  max-height: 400px; /* Prevents excessive growth */
  overflow-y: auto; /* Adds scrollbar when needed */
  word-wrap: break-word; /* Wraps long words */
  white-space: pre-wrap; /* Allows text to break and wrap */
  border: 1px solid #ccc;
  padding: 10px;
  background: #fff;
  resize: vertical; /* Allows resizing of the editor */
}
.whatsapp-background {
  background-color: #ece5dd;
  display: flex;
  justify-content: center;
  align-items: center;
  /* min-height: 50vh; */
  padding: 20px;
}