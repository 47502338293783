.start-date,
.end-date {
  width: 500px !important;
}

.inventory-report th,
.inventory-report td,
.transaction-report-table th,
.transaction-report-table td {
  border: 1px dashed black !important;
  white-space: nowrap;
}


.inventory-report thead tr th:first-child,
.transaction-report-table thead tr th:first-child {
  text-align: left !important;
  width: 50px;
}

.inventory-report thead tr th,
.transaction-report-table thead tr th {
  
  color: white !important;
  text-align: center;
}

.inventory-report td:nth-child(5),
.transaction-report-table td:nth-child(8) {
  text-align: right !important;
  width: 90px !important;
}

.inventory-report .factories-total td {
  background-color: #90a1a3 !important;
  color: white;
}

.transaction-report-table tbody tr td,
.inventory-report tbody tr td {
  text-align: left;
  padding: 2px 6px !important;
}

.transaction-report-table tbody tr td:nth-child(7) {
  text-align: right !important;
}

.scanButton {
  display: none !important;
}


@media screen and (max-width: 380px) and (min-width: 331px) {
  .filter-body div {
    margin: 1.5px 0 !important;
  }

  .scanButton {
    display: inline !important;
    white-space: nowrap !important;
  }
}


@media screen and (max-width: 700px) and (min-width: 381px) {
  .filter-body div {
    margin: 1.5px 0 !important;
  }

  .scanButton {
    display: inline !important;
  }
}


@media screen and (max-width: 1024px) and (min-width: 768px) {
  .filter-body div {
    margin: 1.5px 0 !important;
    white-space: nowrap !important;
  }

  .scanButton {
    display: inline !important;
    white-space: nowrap !important;
  }
}