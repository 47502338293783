.navbar {
    padding: 15px 10px;
    background: #fff;
    border: none;
    border-radius: 0;
    margin-bottom: 11px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.navbar-btn {
    box-shadow: none;
    outline: none !important;
    border: none;
}

.line {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #ddd;
    margin: 40px 0;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

.wrapper {
    display: flex;
    width: 100%;
    align-items: stretch;
    overflow: hidden;
}

#sidebar {
    min-width: 250px;
    max-width: 250px;
    background: #293846;
    color: #a9b7d0;
    transition: all 0.3s;
}
#minisidebar{
    min-width: 90px;
    max-width: 90px;
    background: #1a293b;
    color: #a9b7d0;
    transition: all 0.3s;
}
#sidebar.active {
    display: none;
}
#minisidebar.active{
    display: none;
}

#sidebar .sidebar-header, #minisidebar .sidebar-header{
    padding: 20px;
    background: #1a293b;
}




#sidebar ul li a, #minisidebar ul li a {
    padding: 10px;
    font-size: 1em;
    display: block;
    text-decoration: none;
    color: #a9b7d0;
    padding-left: 1rem;
}

/* #sidebar ul li a:hover,#minisidebar ul li a:hover {
    background: #1a293b;
    color: #1dc4e9;
} */


/* #sidebar ul li.active>a {
    color: #fff;
    background: #f41b35d6;
} */

a[data-toggle="collapse"] {
    position: relative;
}

#sidebar .dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
}


ul.CTAs {
    padding: 20px;
}

ul.CTAs a {
    text-align: center;
    font-size: 0.9em !important;
    display: block;
    border-radius: 5px;
    margin-bottom: 5px;
}



/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */

#content { 
    width: 100%;
    min-height: 100vh;
    transition: all 0.3s;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (max-width: 768px) {
    #sidebar {
        
    }
    #sidebar.active {
        margin-left: 0;
    }
    #sidebarCollapse span {
        display: none;
    }
}
.minisidebar-header{
    width: 91px;
}


.sidebar-list {
    max-height: 100vh;
    overflow-y: scroll;
    position: fixed;
    scrollbar-width: none;
    -ms-overflow-style: none !important;
    top: 16rem;
    left: 0rem;
    width: 242px;
    padding-bottom: 18rem;
    max-width: 250px;
    min-width: 250px;
    background-color: #293846;
}

.footer-content {
    position: fixed;
    bottom: 0;
    width: 250px;
    left: 0;
    background-color: #293846;
}

.sidebar-img {
    position: fixed;
    background-color: #293846;
    width: 18%;
    top: 0;
    left: .1rem;
    padding-bottom: 2px;
    border-bottom: 1px solid #fff;
    min-width: 248px;
    max-width: 248px;
}

.sidefooter {
    position: fixed;
    bottom: 0rem;
    width: 250px;
    padding: 10px 10px;
    border-radius: 0px 0px 5px;
    background-color: #293846 !important;
}

#sidebar ul li a:hover,
#minisidebar ul li a:hover {
    color: #fff;
    background: #18a689;
    border-radius: 0px 20px 20px 0px;
}

#sidebar ul li.active>a {
    color: #fff;
    background: #18a689;
    margin: 5px 0px;
    border-radius: 0px 20px 20px 0px;

}