body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #3498db;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  margin: auto;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; 
  width: 100vw; 
  background-color: rgba(255, 255, 255, 0.8); 
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.modulbox{
  border: 1px black solid;
  border-radius: 1rem;
  padding: 1rem;
  max-height: 30vh;
  min-height: 30vh;
  overflow-y: auto;
  font-size: 1rem;
}
.appLauncher{
  position: fixed;
 bottom: 5%;
 right:3%;
 cursor: pointer;
 z-index: 50;
}
.all_Quick_launcer{
  position: fixed;
  bottom: 9%;
  right: 0;
  z-index: 40;
  background-color: #c1c1c1;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  
  max-height: 60%;
  
  overflow-y: auto;
  transition: transform 0.7s ease-in-out;
  transform: translateX(0);
}



.loader_forget_passwords svg{
  position: absolute !important;
  top: 40% !important;
  left: 46% !important;
  z-index: 1 !important;
}

.disabled-screen {
  pointer-events: none; 
  opacity: 0.6; 
}
