.fee-receipt-container-receipt {
  padding: 20px;
 
}
.header-left-receipt {
  display: flex;
  flex: 10 0 auto;
  align-items: center; 
  justify-content: flex-start; 
  position: relative;
  padding: 70px;
  
}


.fee-receipt-receipt {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 10px;
  box-sizing: border-box;
}

.receipt-copy-receipt {
  width: 48%;
  border: 1px solid #000;
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 10px;
  page-break-inside: avoid;
}

.copy-type-receipt {
  text-align: right;
  margin-bottom: 10px;
  font-weight: bold;
}

.header-receipt {
  text-align: center;
  margin-bottom: 10px;
}

.header-receipt h1 {
  margin: 1px 0;
  font-size: 20px;
}

.header-receipt p {
  margin: 1px 0;
  font-size: 12px;
}

.receipt-info-receipt {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 10px;
  line-height:11px;
}

.info-left-receipt{
  width: 100%;
  position: relative;
  padding-left: 10px;
  

}
.info-right-receipt {
  width: 100%;
  padding-left: 10px;
  
  
}

.table-receipt {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 4px;
  margin-top: -20px;

}

.table-receipt, .table-receipt th, .table-receipt td {
  border: 1px solid #000;
  text-align: left;
  
}

.table-receipt th {
  background-color: #f2f2f2;
}

.table-receipt td.amount {
  text-align: right;
}

/* .amount-words-receipt, .payment-info-receipt, .remarks-receipt, .footer-receipt {
  margin-bottom: 1px;
} */

.footer-receipt {
  text-align: center;
  margin-bottom: 1px;
  margin-top: -20px;
}

@media print {
  .fee-receipt-container-receipt {
    width: 100%;
  }

  .fee-receipt-receipt {
    page-break-before: auto;
    page-break-after: auto;
    page-break-inside: avoid;
  }

  .receipt-copy-receipt {
    page-break-inside: avoid;
  }

  
}

.inline {
  display: inline;
}

.header-receipt {
  margin: 0;
  padding: 5px 0;
  text-align: center;
  font-size: 1.1em;
}
hr {
  margin: 2px 0; 
}
.dotted-line {
  border-left: 2px dotted #000;
  margin: 10 25px;
  height: 100%;
}